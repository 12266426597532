/* ul {
  padding-left: 15px !important;
}

.external-html-container ul li,
p {
  font-family: "Proxima Nova", "Roboto", "Helvetica", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 6px;
} */

.hide {
  display: none !important;
}

.maxed-chars .MuiFormHelperText-root {
  color: #ff5442;
}

.MuiDropzoneArea-root {
  padding: 18.5px 14px !important;
}

.MuiDropzoneArea-root:focus {
  outline-color: #1a444a;
}

.MuiDropzoneArea-root:hover {
  border-color: #1a444a;
}

.MuiDropzoneArea-text.MuiTypography-h5 {
  margin: 0;
  text-align: left;
  font-size: 16px !important;
  color: #909090;
  font-weight: normal;
}

.MuiSvgIcon-root.MuiDropzoneArea-icon {
  color: #eeeeee;
  padding: 16px;
}

.MuiDropzoneSnackbar-successAlert {
  background-color: #1a444a !important;
}

.MuiDropzoneArea-active. {
  border-color: #1a444a;
}

.MuiDropzoneArea-active.MuiDropzoneArea-invalid {
  border-color: #ff5442;
  background-image: none;
  background-color: rgba(254, 151, 134, 0.5);
}

.MuiDropzonePreviewList-imageContainer {
  display: flex;
  align-items: center;
  margin-top: 16px !important;
}

.MuiDropzonePreviewList-imageContainer .MuiDropzonePreviewList-image {
  box-shadow: none;
  margin-right: 16px;
  height: 20px;
  color: #909090;
}

.MuiDropzonePreviewList-removeButton {
  top: 20px !important;
  right: 0 !important;
  box-shadow: none !important;
}

.MuiDropzonePreviewList-removeButton svg.MuiSvgIcon-root {
  color: #1a444a !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1a444a !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: inherit !important;
}

.MuiTableSortLabel-root:hover {
  color: #2d2d2d !important;
}

.MuiTableSortLabel-root:focus {
  color: inherit !important;
}

button.MuiButtonBase-root.MuiIconButton-root.icon-button__backgrounded {
  transition: 0.3s all ease;
  background-color: rgba(238, 238, 238, 0.5);
}

button.MuiButtonBase-root.MuiIconButton-root.icon-button__backgrounded:hover {
  background-color: rgba(238, 238, 238, 0.7);
}

.no-outline fieldset {
  border: none;
}

.external-html-container ul {
  padding-left: 16px;
}

.external-html-container ul li {
  list-style: disc;
}

.external-html-container ul li ul {
  margin-top: 6px;
}

.external-html-container ul li ul li {
  list-style: circle;
}

.external-html-container ul li,
.external-html-container p,
.external-html-container strong {
  font-family: "proxima-nova", "Roboto", "Helvetica", sans-serif;
  font-size: 14px;
  line-height: 1.25;
  margin-bottom: 6px;
  font-weight: 400;
}

.MuiListItem-root.Mui-selected {
  background-color: white;
}
/* 
.MuiFormGroup-root.MuiFormGroup-row[role="radiogroup"]
  .MuiFormControlLabel-label {
  font-size: 10px !important;
} */

.MuiInput-underline:after,
.MuiInput-underline:before,
.MuiInput-underline:hover:not(.Mui-disabled):before,
.MuiFilledInput-underline:before,
.MuiFilledInput-underline:before,
.MuiFilledInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}

.white-text .MuiInputBase-root {
  color: white !important;
}

.MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiFilledInput-adornedEnd.MuiInputBase-marginDense.MuiFilledInput-marginDense {
  border-radius: 3px;
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.MuiInputLabel-root {
  color: #1a444a;
}

.MuiRadio-root .MuiSvgIcon-root {
  fill: #1a444a;
}

.MuiOutlinedInput-notchedOutline legend span {
  color: #1a444a;
}
