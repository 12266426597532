/* React Datepicker */
.react-datepicker {
  border: 1px solid #eeeeee;
}

.react-datepicker__header {
  border-bottom: 1px solid #eeeeee;
}

.react-datepicker-popper {
  z-index: 5;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: #eeeeee;
}

.react-datepicker__input-container {
  position: relative;
  border-radius: 4px;
  color: #000000;
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 16px;
  box-sizing: border-box;
  align-items: center;
  font-family: "Proxima Nova", "Roboto", "Helvetica", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
}

.react-datepicker__input-container input {
  padding: 18.5px 14px;
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  max-height: 56px;
  margin: 0;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.reporting .react-datepicker__input-container input {
  padding: 0.6rem;
}

.react-datepicker__input-container input:focus {
  outline-color: #3f5e62;
}

.datepicker-notched-outline {
  border-color: rgba(0, 0, 0, 0.23);
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  pointer-events: none;
}

/* .react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #fba926;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #fba926;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #e2900d;
} */

/* .react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #e2900d;
} */

/* Full Calendar */
.fc-theme-standard td,
.fc-theme-standard th,
table {
  border: none !important;
}

.fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 18px;
}

.fc .fc-toolbar-title {
  font-size: 0.85em;
  padding-left: 18px;
}

a.fc-col-header-cell-cushion {
  font-size: 0.85em;
  color: #989898;
  font-weight: normal;
}

.fc .fc-daygrid-day-number {
  font-size: 0.85em !important;
  color: #1b444a;
}

.fc .fc-daygrid-day-top {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

th.fc-col-header-cell.fc-day.fc-day-sun,
th.fc-col-header-cell.fc-day.fc-day-mon,
th.fc-col-header-cell.fc-day.fc-day-tue,
th.fc-col-header-cell.fc-day.fc-day-wed,
th.fc-col-header-cell.fc-day.fc-day-thu,
th.fc-col-header-cell.fc-day.fc-day-fri,
th.fc-col-header-cell.fc-day.fc-day-sat {
  padding-bottom: 1em;
}

.fc .fc-button-primary {
  background-color: transparent;
  border: none;
  color: #1b444a;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 0.85em;
}

.fc .fc-button-primary:hover,
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
  color: #1b444a;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.fc .fc-button-primary:disabled {
  background-color: #3f5e62;
}

/* .fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(251, 169, 38, 0.85);
} */

.fc .fc-daygrid-event-harness-abs,
.fc .fc-daygrid-event-harness {
  cursor: default;
}

.fc-scroller.fc-scroller-liquid-absolute {
  overflow: hidden !important;
}
